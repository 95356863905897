import React from "react";
//hello
export const Test = () => {

	// const itens = [
	// 	{
	// 		quantidade: 200,
	// 		marca: "LARANJA",
	// 		descricao: "LARANJA  CX",
	// 		data: "20211222",
	// 		codigo: "0000000001",
	// 		item: "0013",
	// 		produto: "BEAG001",
	// 		valordoproduto: 3,
	// 		frete: 0.16,
	// 		st: 0.3,
	// 		icms: 0.6,
	// 		ipi: 34.45,
	// 		mva: 0.7,
	// 		codbarras: "123456789012",
	// 		formapagamento: 1,
	// 		desconto: 0.03
	// 	},
	// 	{
	// 		quantidade: 1,
	// 		marca: "CUMARI",
	// 		descricao: "  MOLHO  PIMENTA  CUMARI  MARATELLI",
	// 		data: "20211130",
	// 		codigo: "0000000001",
	// 		item: "0001",
	// 		produto: "BERF032",
	// 		valordoproduto: 3,
	// 		frete: 0.16,
	// 		st: 2,
	// 		icms: 0,
	// 		ipi: 0,
	// 		mva: 0,
	// 		codbarras: "123456789012",
	// 		formapagamento: 1,
	// 		desconto: 0.03
	// 	},
	// 	{
	// 		quantidade: 1,
	// 		marca: "MOLHO",
	// 		descricao: "MOLHO DE ALHO COM PIMENTA",
	// 		data: "20211222",
	// 		codigo: "0000000001",
	// 		item: "0011",
	// 		produto: "BERF039",
	// 		valordoproduto: 3,
	// 		frete: 0.16,
	// 		st: 0,
	// 		icms: 0,
	// 		ipi: 0,
	// 		mva: 0,
	// 		codbarras: "123456789012",
	// 		formapagamento: 1,
	// 		desconto: 0.03
	// 	},
	// 	{
	// 		quantidade: 1,
	// 		marca: "DOCE",
	// 		descricao: "DOCE PE DE COCO",
	// 		data: "20211222",
	// 		codigo: "0000000001",
	// 		item: "0006",
	// 		produto: "LADO003",
	// 		valordoproduto: 3,
	// 		frete: 0.16,
	// 		st: 0,
	// 		icms: 0,
	// 		ipi: 0,
	// 		mva: 0,
	// 		codbarras: "123456789012",
	// 		formapagamento: 1,
	// 		desconto: 0.03
	// 	},
	// 	{
	// 		quantidade: 1,
	// 		marca: "DOCE",
	// 		descricao: "DOCE DE BANANADA",
	// 		data: "20211222",
	// 		codigo: "0000000001",
	// 		item: "0008",
	// 		produto: "LADO013",
	// 		valordoproduto: 3,
	// 		frete: 0.16,
	// 		st: 2.3,
	// 		icms: 4.5,
	// 		ipi: 2.4,
	// 		mva: 0.2,
	// 		codbarras: "123456789012",
	// 		formapagamento: 1,
	// 		desconto: 0.03
	// 	},
	// 	{
	// 		quantidade: 1,
	// 		marca: "ACAFRAO",
	// 		descricao: "ACAFRAO CAIPIRA  GARRAFA  900",
	// 		data: "20211222",
	// 		codigo: "0000000001",
	// 		item: "0007",
	// 		produto: "LATE008",
	// 		valordoproduto: 3,
	// 		frete: 0.16,
	// 		st: 0,
	// 		icms: 0,
	// 		ipi: 0,
	// 		mva: 0,
	// 		codbarras: "123456789012",
	// 		formapagamento: 1,
	// 		desconto: 0.03
	// 	},
	// 	{
	// 		quantidade: 1,
	// 		marca: "MEL",
	// 		descricao: "MEL PURO",
	// 		data: "20211222",
	// 		codigo: "0000000001",
	// 		item: "0012",
	// 		produto: "LATE070",
	// 		valordoproduto: 3,
	// 		frete: 0.16,
	// 		st: 0,
	// 		icms: 0,
	// 		ipi: 0,
	// 		mva: 0,
	// 		codbarras: "123456789012",
	// 		formapagamento: 1,
	// 		desconto: 0.03
	// 	},
	// 	{
	// 		quantidade: 1,
	// 		marca: "MORANGO",
	// 		descricao: "MORANGO",
	// 		data: "20211222",
	// 		codigo: "0000000001",
	// 		item: "0009",
	// 		produto: "VEFR179",
	// 		valordoproduto: 3,
	// 		frete: 0.16,
	// 		st: 0,
	// 		icms: 0,
	// 		ipi: 0,
	// 		mva: 0,
	// 		codbarras: "123456789012",
	// 		formapagamento: 1,
	// 		desconto: 0.03
	// 	},
	// 	{
	// 		quantidade: 5,
	// 		marca: "S/N",
	// 		descricao: "ABOBRINHA",
	// 		data: "20211130",
	// 		codigo: "0000000001",
	// 		item: "0003",
	// 		produto: "VEVE003",
	// 		valordoproduto: 3,
	// 		frete: 0.16,
	// 		st: 0,
	// 		icms: 0,
	// 		ipi: 0,
	// 		mva: 0,
	// 		codbarras: "123456789012",
	// 		formapagamento: 1,
	// 		desconto: 0.03
	// 	},
	// 	{
	// 		quantidade: 60,
	// 		marca: "HHHH",
	// 		descricao: "CEBOLA ROXA",
	// 		data: "20211130",
	// 		codigo: "0000000001",
	// 		item: "0005",
	// 		produto: "VEVE016",
	// 		valordoproduto: 3,
	// 		frete: 0.16,
	// 		st: 0,
	// 		icms: 0,
	// 		ipi: 0,
	// 		mva: 0,
	// 		codbarras: "123456789012",
	// 		formapagamento: 1,
	// 		desconto: 0.03
	// 	},
	// 	{
	// 		quantidade: 2,
	// 		marca: "S/N",
	// 		descricao: "ABACAXI PEQUENO",
	// 		data: "20211108",
	// 		codigo: "0000000001",
	// 		item: "0002",
	// 		produto: "VEVE017",
	// 		valordoproduto: 233,
	// 		frete: 12.28,
	// 		st: 0,
	// 		icms: 0,
	// 		ipi: 0,
	// 		mva: 0,
	// 		codbarras: "123456789012",
	// 		formapagamento: 1,
	// 		desconto: 2.33
	// 	},
	// 	{
	// 		quantidade: 1,
	// 		marca: "OVOS",
	// 		descricao: "OVOS VERMELHO 1/2 DZ",
	// 		data: "20211222",
	// 		codigo: "0000000001",
	// 		item: "0010",
	// 		produto: "VEVE061",
	// 		valordoproduto: 3,
	// 		frete: 0.16,
	// 		st: 0,
	// 		icms: 0,
	// 		ipi: 0,
	// 		mva: 0,
	// 		codbarras: "123456789012",
	// 		formapagamento: 1,
	// 		desconto: 0.03
	// 	},
	// 	{
	// 		quantidade: 7,
	// 		marca: "ABC",
	// 		descricao: "ACELGA",
	// 		data: "20211130",
	// 		codigo: "0000000001",
	// 		item: "0004",
	// 		produto: "VEVE090",
	// 		valordoproduto: 3,
	// 		frete: 0.16,
	// 		st: 0,
	// 		icms: 0,
	// 		ipi: 0,
	// 		mva: 0,
	// 		codbarras: "123456789012",
	// 		formapagamento: 1,
	// 		desconto: 0.03
	// 	}
	// ]

	function teste() {
		try {
			//imprimir(itens, false)
		} catch (e) {
			console.log(e)
		}

	}

	return <button onClick={teste} type="button">Hello</button>

}